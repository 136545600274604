import './Cat.css'
import {Button, Container} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function Cat(props) {
    return (
        <Container className={'align-items-center d-flex flex-column'}>
            <div className={'flynn-border'}>
                <img className={'flynn-img'} src={'flynn.png'} alt={'Flynn'}/>
            </div>
            <div className={'flynn-wrapper'}>
                <div className={'flynn-title'}>
                    Flynn!
                </div>
                <Link to={'Portfolio/Face2Cat'}>
                    <Button size={"sm"} variant={'outline-dark'}>
                        <span className={'flynn-subtitle'}> Checkout Face2Cat </span>
                    </Button>
                </Link>
            </div>
        </Container>
    );
}