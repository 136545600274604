// This code is terrible but I am rushing to get this out by valentines day.

import './Valentines.css'
import {Button, Col, Container, Row, InputGroup, FormControl, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useState} from "react";


let chessBoard = [null, null, null, null, null, null, null, null];

let wordBoard = [null, null, null, null, null, null, null, null];

function puzzleHint(id) {
    return [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Happy Valentines Day!",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
    ][id];
}

function puzzleName(id) {
    return [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Beat Tic-Tac-Toe!",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "End",
    ][id];
}

export function calculateTicTacToeWinner(squares) {
    let draw = true;

    for (let i = 0; i < 5; i++) {
        for (let j = 0; j < 5; j++) {
            // down
            if (!squares[i][j] && i !== 0 && i !== 4 && j !== 0 && j !== 4) {
                draw = false;
            }

            if (i < 3 && squares[i][j] && squares[i][j] === squares[i + 1][j] && squares[i + 1][j] === squares[i + 2][j]) {
                return squares[i][j];
            }
            // right
            if (j < 3 && squares[i][j] && squares[i][j] === squares[i][j + 1] && squares[i][j + 1] === squares[i][j + 2]) {
                return squares[i][j]
            }
            // down right
            if (i < 3 && j < 3 && squares[i][j] && squares[i][j] === squares[i + 1][j + 1] && squares[i + 1][j + 1] === squares[i + 2][j + 2]) {
                return squares[i][j]
            }
            // down left
            if (i < 3 && j > 1 && squares[i][j] && squares[i][j] === squares[i + 1][j - 1] && squares[i + 1][j - 1] === squares[i + 2][j - 2]) {
                return  squares[i][j];
            }
        }
    }

    if (draw) {
        return 'C';
    }
    return null;
}

function getOpposingMove(board) {
    // AI to make its turn
    let bestScore = Infinity;
    let move;
    for (let i = 1; i < 4; i++) {
        for (let j = 1; j < 4; j++) {
            // Is the spot available?
            if (board[i][j] === null) {
                board[i][j] = 'O';
                let score = minimax(board, 0, true);
                board[i][j] = null;
                if (score < bestScore) {
                    bestScore = score;
                    move = { i, j };
                }
            }
        }
    }
    return [move.i, move.j];
}


let count = 0;

function minimax(board, depth, isMaximizing) {
    let scores = {'C': 0, 'X': 10, 'O': -10}
    let result = calculateTicTacToeWinner(board);

    if (result !== null) {
        return scores[result];
    }

    if (isMaximizing) {
        let bestScore = -Infinity;
        for (let i = 1; i < 4; i++) {
            for (let j = 1; j < 4; j++) {
                // Is the spot available?
                if (board[i][j] === null) {
                    board[i][j] = 'X';
                    let score = minimax(board, depth + 1, false);
                    board[i][j] = null;
                    bestScore = Math.max(score, bestScore);
                }
            }
        }
        return bestScore;
    } else {
        let bestScore = Infinity;
        for (let i = 1; i < 4; i++) {
            for (let j = 1; j < 4; j++) {
                // Is the spot available?
                if (board[i][j] === null) {
                    board[i][j] = 'O';
                    let score = minimax(board, depth + 1, true);
                    board[i][j] = null;
                    bestScore = Math.min(score, bestScore);
                }
            }
        }
        return bestScore;
    }
}


function moveTicTacToe(board, row, col) {
    let boardCopy =  board.map(row => row.map(col => col))
    let done = false;
    let winner = calculateTicTacToeWinner(boardCopy)

    if (winner && winner !== 'C') {
        return {board: boardCopy, done, winner}
    }

    if (!boardCopy[row][col]) {
        boardCopy[row][col] = 'X'
    } else {
        return {board: boardCopy, done, winner};

    }
    winner = calculateTicTacToeWinner(boardCopy)
    if (winner) {
        done = true;
        return {board: boardCopy, done, winner};
    }

    let [opposingRow, opposingCol] = getOpposingMove(boardCopy);
    if (!boardCopy[opposingRow][opposingCol]) {
        boardCopy[opposingRow][opposingCol] = 'O'
    }
    winner = calculateTicTacToeWinner(boardCopy)
    if (winner) {
        done = true;
    }


    return {board: boardCopy, done, winner};
}

function TicTacToe(props) {
    const [tictactoeBoard, setBoard] = useState([
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null]
    ]);

    return (
        <div className={'tiles'}>
            {Array.from(tictactoeBoard.keys()).map((i) => (
              <Row className={'ttt-row'}>
                  {Array.from(tictactoeBoard[i].keys()).map((j) => (
                      <Col className={(i === 0 || i === 4 || j === 0 || j === 4 ) ? 'tile hidden' : 'tile'} onClick={() => {
                          const {board, done, winner} = moveTicTacToe(tictactoeBoard, i, j);
                          setBoard(board);
                          if (done && winner === "X") {
                              props.revealHint();
                          }

                      } }>
                          {tictactoeBoard[i][j]}
                      </Col>
                  ))}
              </Row>
            ))}
            <Button className={'reset-button'} variant={"outline-secondary"} onClick={() => setBoard([
                [null, null, null, null, null],
                [null, null, null, null, null],
                [null, null, null, null, null],
                [null, null, null, null, null],
                [null, null, null, null, null]
            ])}> Reset </Button>
        </div>
    );
}

function puzzleContent(id, revealHint) {
    return [
        "1D Chess",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        <TicTacToe revealHint={revealHint}/>,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "End",
    ][id];
}


function PuzzleModal(props) {
    const [hint, setHint] = useState("Result");

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h2>Puzzle {props.id + 1}</h2>
                    <h5>{puzzleName(props.id)}</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {puzzleContent(props.id, () => setHint(puzzleHint(props.id)))}
                <div className={'hint'}>{hint}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {setHint("Result"); props.onHide();}}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


function PuzzleLink(props) {
    return <Button onClick={props.onClick} variant={"outline-secondary"} className={"grid-button"}>{props.id + 1}</Button>
}

export default function Valentines(props) {
    const [code, setCode] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [modalId, setModalId] = useState(null);


    return (
        <Container>
            <PuzzleModal show={modalShow} onHide={() => setModalShow(false)} id={modalId}/>
            <Row className={"grid-row"} lg={4} md={4} sm={2} xs={1} >
                {Array.from({length: 20}, (x, i) =>
                    <Col className={'grid-col'}>
                        <PuzzleLink onClick={() => {setModalId(i); setModalShow(true)}} id={i}/>
                    </Col>)
                }
            </Row>
            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        <InputGroup className="mb-3">*/}
            {/*            <FormControl*/}
            {/*                placeholder="Code"*/}
            {/*                aria-label="Code"*/}
            {/*                aria-describedby="basic-addon2"*/}
            {/*                name="code"*/}
            {/*                onChange={(e) => setCode(e.target.value)}*/}
            {/*            />*/}
            {/*            <InputGroup.Append>*/}
            {/*                <Button as={Link} to={"/Valentines/" + code} variant="outline-secondary">*/}
            {/*                    Try!*/}
            {/*                </Button>*/}
            {/*            </InputGroup.Append>*/}
            {/*        </InputGroup>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </Container>
    );
}