import './App.css';
import {Nav, Navbar} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faGithub, faLinkedin, faResearchgate} from '@fortawesome/free-brands-svg-icons'
import {faCoffee} from '@fortawesome/free-solid-svg-icons'
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom"
import {Blog, Cat, Coffee, Coursework, NotFound, Portfolio, Resume, Valentines, Welcome} from "./Components";

function App() {
    return (
        <div className="App">
            <Router>
                <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
                    <Navbar.Brand as={Link} to='/'>
                        <img
                            src={"logo512.png"}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="J"
                        />
                        <span> Josmfred </span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link as={Link} to={'/Resume'}>
                                Resume
                            </Nav.Link>
                            <Nav.Link as={Link} to={'/Portfolio'}>
                                Portfolio
                            </Nav.Link>
                            <Nav.Link as={Link} to={'/Coursework'}>
                                Coursework
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

                <div className={'page'}>
                    <Switch>
                        <Route exact={true} path={'/'} component={Welcome}/>}/>
                        <Route exact={true} path={'/Resume'} component={Resume}/>
                        <Route path={'/Portfolio'} component={Portfolio}/>
                        <Route exact={true} path={'/Coffee'} component={Coffee}/>
                        <Route exact={true} path={'/Cat'} component={Cat}/>
                        <Route path={'/Coursework'} component={Coursework}/>
                        <Route path={'/Blog'} component={Blog}/>
                        <Route path={'/Valentines'} component={Valentines}/>
                        <Route component={NotFound}/>
                    </Switch>
                </div>

                <footer className="footer bg-dark">
                    <div className={'content'}>
                        <a href={'https://github.com/josmfred'} className={'media-icon'}>
                            <FontAwesomeIcon icon={faGithub} size={'lg'}/>
                        </a>
                        <a href={'https://www.linkedin.com/in/jmfreder1/'} className={'media-icon'}>
                            <FontAwesomeIcon icon={faLinkedin} size={'lg'}/>
                        </a>
                        <a href={'https://www.researchgate.net/profile/Joshua_Frederick'} className={'media-icon'}>
                            <FontAwesomeIcon icon={faResearchgate} size={'lg'}/>
                        </a>
                        <Link className={'media-icon'} to={'/Coffee'}>
                            <FontAwesomeIcon icon={faCoffee} size={'lg'}/>
                        </Link>
                    </div>
                    <div className={'copyright'}>
                        Copyright © 2020 Joshua Frederick
                    </div>
                    <Link to={'/Cat'}>
                        <img className={'corner-cat'} src={'black-cat-is-curious-square.png'} alt={'cat'}/>
                    </Link>
                </footer>
            </Router>
        </div>
    );
}

export default App;
