import './Resume.css'
import {
    Row,
    Col,
} from 'react-bootstrap';

export default function Resume() {
    return (
        <Row>
            <Col></Col>
            <Col></Col>
        </Row>
    );
}