import './NotFound.css'
import {Container} from "react-bootstrap";

export default function NotFound(props) {
    return (
            <Container>
                <h1>
                    404
                </h1>
                <p>
                    <strong> Page not Found. :( </strong>
                </p>
                <p>
                    This website is in the early stages of development.
                    It is possible this page should exist but has not been created yet.
                    Feel free to log an issue on <a href={'https://github.com/josmfred/josmfred_website'}> https://github.com/josmfred/josmfred_website </a> if you believe you arrived here in error.
                </p>
            </Container>
    );
}